import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ContactSection } from '../components/contact-section';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { InstagramWidget } from '../components/instagram-widget';
import { FAQs } from '../components/faqs';

function FAQsPage(): React.ReactElement {
  return (
    <>
      <SEO
        title="FAQ"
        description="Welcome to Adore Beauty and Cosmetic Tattoo an elite beauty and cosmetic tattoo studio located in the beautiful coastal town of Port Macquarie NSW. Relax and unwind with our customised cosmetic tattoo and beauty services."
      />
      <Layout>
        <Hero />
        <FAQs />
        <InstagramWidget />
        <ContactSection />
      </Layout>
    </>
  );
}

function Hero(): React.ReactElement {
  return (
    <div className="relative hidden max-h-screen sm:block">
      {/* <div className="absolute md:hidden -top-24">
        <StaticImage
          quality={90}
          width={700}
          src="../images/hero-bg.png"
          alt=""
          className="flex-1 w-full object-full"
        />
      </div> */}
      <div className="absolute inset-0 flex">
        <StaticImage
          quality={90}
          src="../images/faqs-hero.png"
          alt=""
          className="flex-1 object-cover"
        />
        <div className="absolute inset-0 flex">
          <div className="flex-1 hero-bg-filter" />
        </div>
      </div>
      <div className="relative w-full px-4 py-24 mx-auto md:py-48 sm:px-6 lg:px-8 max-w-screen-2xl">
        <h1 className="text-4xl font-semibold text-center text-custom-gradient md:text-left">
          Adore's FAQs
        </h1>
      </div>
    </div>
  );
}

export default FAQsPage;
